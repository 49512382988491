<template>
  <div class="g-box g-main-box">
    <div
      class="u-nav-box"
      v-loading="!loadMenu"
      element-loading-background="rgba(0, 0, 0, 0.3)"
      :class="{ 's-expand': !expand }"
    >
      <div class="u-h3" v-if="expand">
        <div class="menu-logo-svg"></div>
      </div>
      <div class="u-h3 s-expand" v-else></div>
      <!--      <h3 v-show="!getLogo&&!getMiniLogo" class="u-h3" :class="{'s-expand':!expand}"></h3>-->
      <m-nav :expand="expand" v-if="loadMenu"></m-nav>
      <!-- <div v-else><span class="ml-15">加载中...</span></div> -->
    </div>
    <div class="u-main" :data-mini="!expand">
      <m-header
        class="m-header"
        @expandChange="(val) => (expand = val)"
        :api-post-menu-build-menu="api.systemBase.postMenuBuildMenu"
        :api-post-park-park-out="api.systemBase.postParkParkOut"
        :path="config.pathZeus"
        :local-storage="local"
        :system-name="getSystemName"
      ></m-header>
      <div class="u-body">
        <router-view ref="tableRef" id="tableRef"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import api from './../api'
import LogoSvg from '../assets/logo.svg?inline'

export default Vue.extend({
  name: 'main-box',
  components: {
    LogoSvg
  },
  directives: {},
  filters: {},
  props: {
  },
  data () {
    return {
      api: api,
      loadMenu: false,
      expand: true,
      userType: VueCookie.get('role'),
      breadList: [],
      local: Vue.local,
      logo: '', // 园区logo
      miniLogo: '', // 园区minilogo
      systemName: '', // 系统名称
      defaultSystemName: ''
    }
  },
  computed: {
    title () {
      if (this.userTyped === 'BANKUSER' || this.userTyped === 'SUPERUSER') return '超管后台'
      return '智慧园区'
    },
    getLogo () {
      return this.logo || VueCookie.get('richeninfo-logo') || ''
    },
    getMiniLogo () {
      return this.miniLogo || VueCookie.get('richeninfo-minilogo') || ''
    },
    getSystemName () {
      return this.systemName || VueCookie.get('richeninfo-systemName') || this.defaultSystemName
    }
  },
  watch: {
    $route () {
      this.breadList = []
      this.breadList = (this.tool.recursionFindProp(Vue.local.get('navData')
        .filter(item => item.code === this.config.name), 'name', this.$route.name) || []).reverse()
      // this.init()
    }
  },
  beforeCreate () {
  },
  created () {
    this.breadList = []
    this.init()
  },
  beforeMount () {
  },
  mounted () {
    this.bus.$on('uploadLogoAndTitle', val => {
      this.systemName = val.systemName || this.defaultSystemName
      this.logo = val.logo || ''
      this.miniLogo = val.miniLogo || ''
      VueCookie.set('richeninfo-logo', val.logo)
      VueCookie.set('richeninfo-minilogo', val.miniLogo)
      VueCookie.set('richeninfo-systemName', val.systemName)
    })
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    init () {
      api.systemBase.postUserMenu({
        type: '1'
      }).then(res => {
        Vue.local.set('navData', res.data || [], 1000 * 60 * 60 * 24 * 31)
        this.loadMenu = true
        this.breadList = (this.tool.recursionFindProp(Vue.local.get('navData')
          .filter(item => item.code === this.config.name), 'name', this.$route.name) || []).reverse()
        this.getLogoAndName()
        this.$store.commit('setMenuList', res.data)
        this.$store.commit('setMenuCode', res.data)
        this.loading = false
      })

      // api.systemBase.getUserOrgList({}).then(res => {
      //   console.log('user', res)
      //   this.$store.commit('setUser', res.data)
      // })
    },
    funRefresh () {
      if (!this.$refs.tableRef.onSubmit) return false
      this.$refs.tableRef.onSubmit()
    },
    getLogoAndName () {
      // if (VueCookie.get('parkId')) {
      //   api.systemBase.postParkFindById({
      //     parkId: VueCookie.get('parkId')
      //   }).then(res => {
      //     this.systemName = res.data.systemName || this.defaultSystemName
      //     this.logo = res.data.logo || ''
      //     this.miniLogo = res.data.miniLogo || ''
      //     VueCookie.set('richeninfo-logo', res.data.logo || '')
      //     VueCookie.set('richeninfo-minilogo', res.data.miniLogo || '')
      //     VueCookie.set('richeninfo-systemName', res.data.systemName || '')
      //   })
      // } else {
      // api.systemBase.postQuerySystemConfig({}).then(res => {
      //   this.systemName = res.data.systemName || this.defaultSystemName
      //   this.logo = res.data.logo || ''
      //   this.miniLogo = res.data.miniLogo || ''
      //   VueCookie.set('richeninfo-logo', res.data.logo || '')
      //   VueCookie.set('richeninfo-minilogo', res.data.miniLogo || '')
      //   VueCookie.set('richeninfo-systemName', res.data.systemName || '')
      // })
      // }
    }
  }
})
</script>
<style lang="scss">
.s-bread-item {
  .el-breadcrumb__inner {
    color: #000000 !important;
  }
  .el-breadcrumb__separator {
    color: #000000 !important;
  }
}
.s-bread-item-active {
  .el-breadcrumb__inner {
    color: #0677ff !important;
  }
}
</style>
<style lang="scss" scoped>
@import "./../assets/css/global-variable.scss";
.g-main-box {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: $fc-bg-shallow;
  .u-nav-box {
    overflow-y: auto;
    overflow-x: hidden;
    width: 208px;
    min-width: 208px;
    background-color: #206e55;
    box-shadow: 2px 0px 8px rgba(4, 20, 1, 0.08);
    border-radius: 0px 0px 20px 0px;
    position: relative;
    padding-top: 75px;
    transition: all ease 0.3s;
    border-bottom-right-radius: 20px;
    &.s-expand {
      width: 64px;
      min-width: 64px;
      /deep/ .el-menu i {
        margin-left: 0 !important;
      }
    }

    .menu-logo-svg {
      width: 100px;
      height: 50px;
      background: url("~assets/img/logo.png") no-repeat;
      background-size: 100%;
      margin-left: -22px;
    }

    .u-h3 {
      position: absolute;
      left: 0px;
      top: 20px;
      background-size: cover;
      line-height: 40px;
      font-size: 18px;
      transition: all ease 0.3s;
      padding-left: 45px;
      width: 100px;
      height: 64px;
      position: absolute;
      z-index: 30;

      &.s-expand {
        width: 40px;
        height: 40px;
        padding-left: 0px;
        left: 12px;
      }
    }
    .logo-box {
      width: 156px;
      height: 40px;
      position: absolute;
      left: 30px;
      top: 20px;
      &.s-expand {
        left: 12px;
      }
      .logo-img {
        width: 156px;
        height: 40px;
        &.s-expand {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .u-main {
    display: flex;
    //flex: 1;
    width: calc(100% - 208px);
    flex-direction: column;
    transition: all ease 0.3s;
    &[data-mini] {
      width: calc(100% - 65px);
    }
    .m-header {
    }
    .u-body {
      overflow-y: auto;
      padding: 30px 30px 30px;
      flex: 1;
      box-sizing: border-box;
      position: relative;
      background: #f5f7f9;
      .u-bread {
        position: absolute;
        top: 18px;
        left: 20px;
        .u-refresh {
          float: left;
          cursor: pointer;
          color: $fc-theme;
        }
        .u-pos {
          float: left;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
